<template>
	<errorContainer :error="erreur" :warning="warning">
		<transition name="slide">
			<div
				:class="{
					'container-layout': $screen.width >= 992,
					'container-mobile': $screen.width < 992
				}"
				class="p-0"
			>
				<b-row class="form">
					<b-col cols="12" xl="12">
						<h1 :class="[`${!$screen.sm && !$screen.md ? 'main-page-title' : ''}`, { 'is-pwa': $isPwa() }]">
							{{ FormMSG(10, 'Timesheet details') }}
						</h1>
						<!-- <pre>{{ curTs }}</pre> -->
						<b-card no-body style="background-color: #fff">
							<!-- bg-secondary text-white -->
							<b-row
								class="back-with-title cursor-pointer d-flex align-items-center pt-0 pb-2 pl-0"
								:class="{ 'mt-16': $isPwa() }"
								v-if="$screen.width >= 992"
							>
								<b-col cols="6">
									<button class="btn-transparent" @click="$router.push('/mytimesheets')">
										<component :is="getLucideIcon('ArrowLeft')" color="rgba(6, 38, 62, 0.65)" :size="22" class="icon" />
										<span class="title-back-bolder mx-0">
											{{ FormMSG(20, 'Back to timesheets') }}
										</span>
									</button>
								</b-col>
								<b-col cols="6" class="inline-flex text-right">
									<div class="date-title pr-3">{{ this.caption2 }}</div>
									<div class="name-title pr-3">{{ curTs.fullName }} <span class="divider-dot"></span> {{ curTs.functionName }}</div>
									<div v-if="useEntryForCrewTimesheet" class="d-flex align-items-center justify-content-end w-100 pr-3">
										<span v-if="!isFilmSingle" class="border-encoded p-1"
											>{{ FormMSG(149, 'Encoded by: ') }} <b>{{ encodedBy }}</b>
										</span>
									</div>
								</b-col>
							</b-row>
							<div v-else>
								<div class="back-with-title d-flex align-items-center cursor-pointer" :class="{ 'mt-16': $isPwa() }" @click="$router.go(-1)">
									<div class="icon">
										<component :is="getLucideIcon('ArrowLeft')" color="#667987" :size="22" />
									</div>
									<h2>{{ FormMSG(20, 'Back to timesheets') }}</h2>
								</div>
								<div class="title-ts-detail text-center py-2">
									<div class="date-title pr-3">{{ this.caption2 }}</div>
									<div class="name-title pr-3">{{ curTs.fullName }} <span class="divider-dot"></span> {{ curTs.functionName }}</div>
								</div>
							</div>
							<b-card-text :class="`${$screen.width >= 992 ? 'px-3 pt-4' : 'px-2 pt-3'}`">
								<div :class="`${$screen.width >= 992 ? 'card card-border-blue-light' : ''}`">
									<b-row>
										<b-col sm="4">
											<b-form-group :label="FormMSG(13, 'Hours:')" label-for="hours">
												<b-form-input :disabled="true" v-model="curTs.hhmm" type="text" id="hours"></b-form-input>
											</b-form-group>
										</b-col>
										<b-col sm="4">
											<b-form-group label="CO2" label-for="kgCoTwo">
												<b-form-input :disabled="true" :value="displayKgCoTwo(curTs.kgCoTwo)" type="text" id="kgCoTwo"></b-form-input>
											</b-form-group>
										</b-col>
										<b-col sm="4" v-if="canSeeSalary">
											<b-form-group :label="FormMSG(17, 'Salary:')" label-for="salary">
												<b-form-input :disabled="true" :value="displayCurrency(curTs.salary)" type="text" id="salary"></b-form-input>
											</b-form-group>
										</b-col>
									</b-row>
									<b-row class="ml-0 w-100" v-if="showOvt() || showOvt1() || showOvt2()">
										<b-col cols="12" class="px-0">
											<fieldset class="scheduler-border border-groove-blue-streak w-100 pb-3">
												<legend class="scheduler-border text-color-blue-streak">
													{{ FormMSG(140, 'Daily Overtime') }}
												</legend>
												<b-row>
													<b-col sm="4" v-if="showOvt()">
														<b-form-group :label="FormMSG(14, 'Overtime:')" label-for="ovt">
															<b-form-input :disabled="true" v-model="curTs.hhmmOvt" type="text" id="ovt"></b-form-input>
														</b-form-group>
													</b-col>
													<b-col sm="4" v-if="showOvt1()">
														<b-form-group :label="FormMSG(121, 'Overtime 1:')" label-for="ovt1" :label-cols="0">
															<b-form-input :disabled="true" v-model="curTs.hhmmOvt1" type="text" id="ovt1"></b-form-input>
														</b-form-group>
													</b-col>
													<b-col sm="4" v-if="showOvt2()">
														<b-form-group :label="FormMSG(131, 'Overtime 2:')" label-for="ovt2" :label-cols="0">
															<b-form-input :disabled="true" v-model="curTs.hhmmOvt2" type="text" id="ovt2"></b-form-input>
														</b-form-group>
													</b-col>
												</b-row>
											</fieldset>
										</b-col>
									</b-row>
									<b-row class="ml-0 w-100" v-if="showWeeklyOvt() || showWeeklyOvt1() || showWeeklyOvt2()">
										<b-col cols="12" class="px-0">
											<fieldset class="scheduler-border border-groove-blue-streak w-100 pb-3">
												<legend class="scheduler-border text-color-blue-streak">
													{{ FormMSG(390, 'Weekly Overtime') }}
												</legend>
												<b-row>
													<b-col sm="4" v-if="showWeeklyOvt()">
														<b-form-group
															style="font-weight: bold"
															:label="FormMSG(11, 'Overtime:')"
															label-for="ovt"
															:label-cols="0"
														>
															<b-form-input
																:disabled="true"
																v-model="curTs.weeklyTotMinOvtStr"
																type="text"
																id="ovt"
															></b-form-input>
														</b-form-group>
													</b-col>
													<b-col sm="4" v-if="showWeeklyOvt1()">
														<b-form-group
															style="font-weight: bold"
															:label="FormMSG(121, 'Overtime 1:')"
															label-for="ovt1"
															:label-cols="0"
														>
															<b-form-input
																:disabled="true"
																v-model="curTs.weeklyTotMinOvt1Str"
																type="text"
																id="ovt1"
															></b-form-input>
														</b-form-group>
													</b-col>
													<b-col sm="4" v-if="showWeeklyOvt2()">
														<b-form-group
															style="font-weight: bold"
															:label="FormMSG(131, 'Overtime 2:')"
															label-for="ovt2"
															:label-cols="0"
														>
															<b-form-input
																:disabled="true"
																v-model="curTs.weeklyTotMinOvt2Str"
																type="text"
																id="ovt2"
															></b-form-input>
														</b-form-group>
													</b-col>
												</b-row>
											</fieldset>
										</b-col>
									</b-row>
									<b-row v-if="showTransport() || showNightHours() || showTooEarlyHours()">
										<b-col sm="4">
											<b-form-group :label="FormMSG(100, 'Transport:')" label-for="transport" :label-cols="0" v-if="showTransport()">
												<b-form-input :disabled="true" v-model="curTs.hhmmTransport" type="text" id="transport"></b-form-input>
											</b-form-group>
										</b-col>
										<b-col sm="4">
											<b-form-group :label="FormMSG(15, 'Night:')" label-for="night" v-if="showNightHours()">
												<b-form-input :disabled="true" v-model="curTs.hhmmNight" type="text" id="night"></b-form-input>
											</b-form-group>
										</b-col>
										<b-col sm="4">
											<b-form-group :label="FormMSG(16, 'Anticipated:')" label-for="antic" v-if="showTooEarlyHours()">
												<b-form-input :disabled="true" v-model="curTs.hhmmTooEarly" type="text" id="antic"></b-form-input>
											</b-form-group>
										</b-col>
									</b-row>
									<b-row>
										<b-col sm="6" v-if="showLunchPerDiem() || showHotelPerDiem() || showDinnerPerDiem() || showAbroadPerDiem()">
											<b-form-group style="font-weight: bold">
												<label for="diem" class="d-flex align-items-center">
													<div>{{ FormMSG(101, 'Per Diem:') }}</div>
													<div id="tooltip-target-1" class="cursor-pointer ml-1" v-if="curTs.weeklyTotPerDiem > 0">
														<component :is="getLucideIcon('Info')" :size="22" color="#225cbd" />
													</div>
													<b-tooltip target="tooltip-target-1" triggers="hover" v-if="curTs.weeklyTotPerDiem > 0">
														<div v-if="curTs.weeklyHotelPerDiem > 0">
															<b>{{ FormMSG(103, 'Hotel: ') }}</b>
															{{ displayCurrency(curTs.weeklyHotelPerDiem) }}
														</div>
														<div v-if="curTs.weeklyLunchPerDiem > 0">
															<b>{{ FormMSG(104, 'Lunch: ') }}</b>
															{{ displayCurrency(curTs.weeklyLunchPerDiem) }}
														</div>
														<div v-if="curTs.weeklyDinnerPerDiem > 0">
															<b>{{ FormMSG(105, 'Dinner: ') }}</b>
															{{ displayCurrency(curTs.weeklyDinnerPerDiem) }}
														</div>
														<div v-if="curTs.weeklyAbroadPerDiem > 0">
															<b>{{ FormMSG(110, 'Abroad: ') }}</b>
															{{ displayCurrency(curTs.weeklyAbroadPerDiem) }}
														</div>
													</b-tooltip>
												</label>
												<b-form-input :disabled="true" :value="displayCurrency(curTs.weeklyTotPerDiem)" type="text" id="diem" />
											</b-form-group>
										</b-col>
										<b-col sm="6">
											<b-form-group style="font-weight: bold">
												<label for="allowances" class="d-flex align-items-center">
													<div>{{ FormMSG(111, 'Allowances:') }}</div>
													<div
														v-if="curTs.dailyTotAllowances > 0 || curTs.weeklyTotAllowances > 0"
														id="tooltip-target-2"
														class="cursor-pointer ml-1"
													>
														<component :is="getLucideIcon('Info')" :size="22" color="#225cbd" />
													</div>
													<b-tooltip
														target="tooltip-target-2"
														v-if="curTs.dailyTotAllowances > 0 || curTs.weeklyTotAllowances > 0"
														triggers="hover"
													>
														<div v-if="curTs.weeklyCarAllowance > 0">
															<b>{{ FormMSG(112, 'Car: ') }}</b>
															{{ displayCurrency(curTs.weeklyCarAllowance) }}
														</div>
														<div v-if="curTs.weeklyComputerAllowance > 0">
															<b>{{ FormMSG(113, 'Computer: ') }}</b>
															{{ displayCurrency(curTs.weeklyComputerAllowance) }}
														</div>
														<div v-if="curTs.weeklyPhoneAllowance > 0">
															<b>{{ FormMSG(114, 'Phone: ') }}</b>
															{{ displayCurrency(curTs.weeklyPhoneAllowance) }}
														</div>
														<div v-if="curTs.weeklyProductionFeeAllowance > 0">
															<b>{{ FormMSG(115, 'Production fee: ') }}</b>
															{{ displayCurrency(curTs.weeklyProductionFeeAllowance) }}
														</div>
														<div v-if="curTs.weeklyBoxKitAllowance > 0">
															<b>{{ FormMSG(116, 'Box kit: ') }}</b>
															{{ displayCurrency(curTs.weeklyBoxKitAllowance) }}
														</div>
														<div v-if="curTs.dailyTotAllowances > 0">
															<b>{{ FormMSG(117, 'Total Daily: ') }}</b>
															{{ displayCurrency(curTs.dailyTotAllowances) }}
														</div>
													</b-tooltip>
												</label>
												<b-form-input
													:disabled="true"
													:value="
														displayCurrency(
															(isNaN(curTs.dailyTotAllowances) ? 0 : curTs.dailyTotAllowances) +
																(isNaN(curTs.weeklyTotAllowances) ? 0 : curTs.weeklyTotAllowances)
														)
													"
													type="text"
													id="allowances"
												></b-form-input>
											</b-form-group>
										</b-col>
									</b-row>
									<b-row>
										<b-col cols="12" sm="12">
											<b-form-group v-if="curTs.comment.length > 0" :label="FormMSG(19, 'Comment:')" label-for="comment" :label-cols="2">
												<b-form-textarea :disabled="true" id="comment" v-model="curTs.comment" rows="2"></b-form-textarea>
											</b-form-group>
										</b-col>
									</b-row>
								</div>
								<div v-if="TsDays" class="card card-border-blue-light mt-3" :style="`${$screen.width <= 992 ? 'margin-bottom: 112px;' : ''}`">
									<b-form-checkbox v-if="canSeeSalary" id="showGridDetails" v-model="showGridDetails" size="lg" class="pj-cb pb-1">
										{{ FormMSG(1224, 'Show salary details') }}
									</b-form-checkbox>

									<b-row>
										<b-col cols="12" xl="12">
											<card-list-builder
												v-if="$screen.width < 992"
												:use-new-version="true"
												:items="TsDays"
												:fields="tsMobileFields"
												:has-badge="true"
												:is-badge-use-props="true"
												badge-class="statusClass"
												:hide-status="false"
												badge-value="validatedStatus"
												:toggle-mode="true"
											>
												<template slot="lunch" slot-scope="data">
													<div class="d-flex justify-content-end align-items-center">
														<button
															v-if="data.item.totPerDiem > 0"
															class="btn-transparent text-color-rhapsody-in-blue"
															v-b-tooltip.hover.left
															:title="tooltipContentPerIdem(data.item)"
														>
															<component :is="getLucideIcon('Info')" color="#225CBD" :size="20" />
														</button>
														<div class="adjust-icon-ts-tsd">{{ displayCurrency(data.item.totPerDiem) }}</div>
													</div>
												</template>
												<template slot="hotel" slot-scope="data">
													<div class="d-flex justify-content-end align-items-center">
														<button
															v-if="data.item.totAllowances > 0"
															class="btn-transparent text-color-rhapsody-in-blue"
															v-b-tooltip.hover.left
															:title="tooltipContentAllowance(data.item)"
														>
															<component :is="getLucideIcon('Info')" color="#225CBD" :size="20" />
														</button>
														<div class="adjust-icon-ts-tsd">{{ displayCurrency(data.item.totAllowances) }}</div>
													</div>
												</template>
												<template slot="rej" slot-scope="data">
													<div class="d-flex justify-content-end align-items-center w-100">
														<component
															v-if="data.item.validated === 4 || data.item.validated === 16"
															:is="getLucideIcon(ICONS.UNDO_2.name)"
															:color="ICONS.UNDO_2.color"
															:size="22"
															:stroke-width="2.5"
															@click="undoRejectTsDay(data.item.id)"
														/>
														<component
															v-else
															:is="getLucideIcon(ICONS.X_CIRCLE.name)"
															:color="ICONS.X_CIRCLE.color"
															:size="22"
															:stroke-width="2.5"
															@click="rejectTsDay(data.item.id)"
														/>
													</div>
												</template>
											</card-list-builder>
											<b-table
												v-if="$screen.width >= 992"
												:hover="hover"
												responsive="sm"
												:items="TsDays"
												:fields="tsFields"
												:current-page="currentPage"
												@row-dblclicked="rowClicked"
												sticky-header="700px"
												:per-page="perPage"
												:head-variant="hv"
												bordered
												small
											>
												<template #cell(dayType)="data">
													<span v-html="data.value" />
												</template>
												<template #cell(hhmm)="data">
													<span v-html="data.value"></span>
												</template>
												<template #cell(hhmmOvt)="data">
													<span v-html="data.value"></span>
												</template>
												<template #cell(hhmmOvt1)="data">
													<span v-html="data.value"></span>
												</template>
												<template #cell(hhmmOvt2)="data">
													<span v-html="data.value"></span>
												</template>
												<template #cell(hhmmTransport)="data">
													<span v-html="data.value"></span>
												</template>
												<template #cell(hhmmNight)="data">
													<span v-html="data.value"></span>
												</template>
												<template #cell(hhmmTooEarly)="data">
													<span v-html="data.value"></span>
												</template>
												<template #cell(remark)="data">
													<Button
														v-if="data.item.remark"
														v-tooltip.left.focus="data.item.remark"
														class="p-0 p-button-text p-button-info"
													>
														<MessageCircle />
													</Button>
												</template>
												<template v-slot:cell(validatedStatus)="data">
													<div class="wrap-status d-flex">
														<div :class="`status ${data.item.statusClass}`" style="font-size: 0.7rem">
															{{ data.item.validatedStatus }}
														</div>
													</div>
												</template>
												<template
													#cell(lunch)="data"
													v-if="showLunchPerDiem() || showHotelPerDiem() || showDinnerPerDiem() || showAbroadPerDiem()"
												>
													<div class="d-flex justify-content-end align-items-center">
														<button
															v-if="data.item.totPerDiem > 0"
															class="btn-transparent text-color-rhapsody-in-blue"
															v-b-tooltip.hover.left
															:title="tooltipContentPerIdem(data.item)"
														>
															<component :is="getLucideIcon('Info')" color="#225CBD" :size="20" />
														</button>
														<div class="adjust-icon-ts-tsd">{{ displayCurrency(data.item.totPerDiem) }}</div>
													</div>
												</template>
												<template #cell(hotel)="data">
													<div class="d-flex justify-content-end align-items-center">
														<button
															v-if="data.item.totAllowances > 0"
															class="btn-transparent text-color-rhapsody-in-blue"
															v-b-tooltip.hover.left
															:title="tooltipContentAllowance(data.item)"
														>
															<component :is="getLucideIcon('Info')" color="#225CBD" :size="20" />
														</button>
														<div class="adjust-icon-ts-tsd">{{ displayCurrency(data.item.totAllowances) }}</div>
													</div>
												</template>
												<template v-slot:cell(rej)="data">
													<b-button
														size="sm"
														class="btn bg-transparent border-0"
														v-if="data.item.validated == 4 || data.item.validated == 16"
														@click="undoRejectTsDay(data.item.id)"
													>
														<component :is="getLucideIcon(ICONS.UNDO_2.name)" :color="ICONS.UNDO_2.color" :size="20" />
													</b-button>
													<b-button size="sm" class="btn bg-transparent border-0" v-else @click="rejectTsDay(data.item.id)">
														<component :is="getLucideIcon(ICONS.X_CIRCLE.name)" :color="ICONS.X_CIRCLE.color" :size="20" />
													</b-button>
												</template>
											</b-table>
										</b-col>
									</b-row>
									<b-row :class="`${$screen.width <= 576 ? 'footer-fixed' : ''}`">
										<b-col md="3" sm="12">
											<div class="w-100 d-flex justify-content-center align-items-center">
												<b-button
													@click="validateTimesheet"
													:disabled="this.canNotValidateTs()"
													size="md"
													variant="outline-success"
													class="d-flex justify-content-center align-items-center"
													:class="`${$screen.width <= 576 ? 'w-100 mb-2' : 'w-80'}`"
												>
													<component :is="getLucideIcon(ICONS.CHECK_SQUARE.name)" :size="20" class="mr-2" />
													<div style="margin-top: 1px">{{ this.FormMSG(30, 'Validate Timesheet') }}</div>
												</b-button>
											</div>
										</b-col>
										<b-col md="3" sm="12">
											<div class="w-100 d-flex justify-content-center">
												<b-button
													@click="downloadXls"
													size="md"
													variant="outline-primary"
													:disabled="isLoadingDownload"
													class="d-flex justify-content-center align-items-center"
													:class="`${$screen.width <= 576 ? 'w-100 mb-2' : 'w-80'}`"
												>
													<b-spinner v-show="isLoadingDownload" class="mr-2" small></b-spinner>
													<component :is="getLucideIcon(ICONS.DOWNLOAD.name)" :size="20" class="mr-2" v-if="!isLoadingDownload" />
													<div style="margin-top: 1px">{{ this.FormMSG(221, 'Download this Timesheet') }}</div>
												</b-button>
											</div>
										</b-col>
										<b-col md="3" sm="12">
											<div class="w-100 d-flex justify-content-center">
												<b-button
													@click="sendXlsByEmail"
													size="md"
													variant="outline-primary"
													:disabled="isLoadingSendMail"
													class="d-flex justify-content-center align-items-center"
													:class="`${$screen.width <= 576 ? 'w-100 mb-2' : 'w-80'}`"
												>
													<b-spinner v-show="isLoadingSendMail" class="mr-2" small></b-spinner>
													<component :is="getLucideIcon(ICONS.SEND.name)" :size="20" class="mr-2" v-if="!isLoadingSendMail" />
													<div style="margin-top: 1px">{{ this.FormMSG(31, 'Send me this Timesheet by Email') }}</div>
												</b-button>
											</div>
										</b-col>
										<b-col md="3" sm="12">
											<div class="w-100 d-flex justify-content-center">
												<b-button
													@click="successModalRejectTimesheet = true"
													size="md"
													variant="outline-danger"
													class="d-flex justify-content-center align-items-center"
													:class="`${$screen.width <= 576 ? 'w-100 mb-2' : 'w-80'}`"
												>
													<component :is="getLucideIcon(ICONS.X_CIRCLE.name)" :size="20" class="mr-2" />
													<div style="margin-top: 1px">{{ this.FormMSG(32, 'Reject Timesheet') }}</div>
												</b-button>
											</div>
										</b-col>
									</b-row>
									<b-modal
										header-class="header-class-modal-doc-package"
										:title="FormMSG(33, 'Success!')"
										class="modal-success"
										v-model="successModal"
										@ok="successModal = false"
										ok-variant="success"
										ok-only
									>
										{{ this.FormMSG(34, 'The timesheet has been sent to your Email address') }}
									</b-modal>
									<b-modal
										header-class="header-class-modal-doc-package"
										:title="this.FormMSG(33, 'Success!')"
										class="modal-success"
										v-model="successModalValidated"
										@ok="successModalValidated = false"
										ok-variant="success"
										ok-only
									>
										{{ this.FormMSG(35, 'The timesheet has been validated') }}
									</b-modal>
									<b-modal
										header-class="header-class-modal-doc-package"
										:title="FormMSG(36, 'Refuse this timesheet')"
										class="modal-danger"
										v-model="successModalRejectTimesheet"
										@ok="this.finaliseTimesheetReject"
										ok-variant="danger"
										ok-only
									>
										<b-form-group :label="FormMSG(37, 'Enter your comment:')" label-for="comment" :label-cols="4">
											<b-form-textarea
												id="comment"
												v-model="rejectComment"
												:placeholder="this.FormMSG(38, 'Enter your remarks...')"
												rows="3"
											></b-form-textarea>
										</b-form-group>
									</b-modal>
									<b-modal
										header-class="header-class-modal-doc-package"
										:title="FormMSG(39, 'Refuse this day')"
										class="modal-danger"
										v-model="successModalRejectedTsDay"
										@ok="this.finaliseTsDayReject"
										ok-variant="danger"
										ok-only
									>
										<b-form-group :label="FormMSG(37, 'Enter your comment:')" label-for="comment" :label-cols="4">
											<b-form-textarea
												id="comment"
												v-model="rejectComment"
												:placeholder="this.FormMSG(38, 'Enter your remarks...')"
												rows="3"
											></b-form-textarea>
										</b-form-group>
									</b-modal>
								</div>
							</b-card-text>
						</b-card>
					</b-col>
				</b-row>
				<script-export-modal
					v-model="isScriptTimeSheetReportOpen"
					:items="scripts"
					:custom="customReport"
					export-type="ts"
					@script-export-modal:loading="handleLoading"
					@script-export-modal:closed="timeSheetRerpotOnClosed"
				/>
			</div>
		</transition>
	</errorContainer>
</template>

<script>
import gql from 'graphql-tag';
import { store } from '@/store';

// import axios from 'axios';
import Loading from 'vue-loading-overlay';
import languageMessages from '@/mixins/languageMessages';
import { rendCurrency, rendKgCo2 } from '~helpers';
import MobileValidateTsDetailsList from '@/components/MobileViews/ValidateTsDetails/List';
import { mapActions } from 'vuex';
import { classStatus } from '@/shared/global-status';
import globalMixin from '@/mixins/global.mixin';
import moment from 'moment';
import { getScripts } from '@/cruds/script.crud';
import { timeSheetReport } from '@/cruds/export.crud';
import ScriptExportModal from '@/components/Export/ScriptExportModal';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
// import * as icons from 'lucide-vue';
import { formatFileName } from '~utils';
import _ from 'lodash';
import { FILENAME_REPORT, OUTPUT_FORMAT_EXPORT } from '@/shared/constants';
import { MessageCircle } from 'lucide-vue';
import { getFileExtension } from '@/shared/helpers';
import Button from 'primevue/button';

const query_tsdays = gql`
	query ($TimesheetId: Int!, $UnLoadNotSubmittedDay: Boolean) {
		TimesheetDetails(TimesheetId: $TimesheetId, UnLoadNotSubmittedDay: $UnLoadNotSubmittedDay) {
			id
			userProjectId
			date
			remark
			strTime
			endTime
			lunchStrTime
			lunchEndTime
			lunch
			hotel
			useDinner
			useAbroad
			teleworking
			totMin
			validated
			timesheetId
			comment
			stageDayUsed
			dayNumber
			salaryBase
			totPerDiem
			salary
			salaryOvertime
			salaryOvertime1
			salaryOvertime2
			salaryHourNight
			salaryHourTooEarly
			totMinOvertime
			leftHomeAt
			returnedHomeAt
			totMinNight
			totMinTooEarly
			dayType
			dayRate
			kgCoTwo
			totMinOvertime1
			totMinOvertime2
			transportTimePaid
			salaryTransport
			carAllowance
			productionFeeAllowance
			computerAllowance
			phoneAllowance
			boxKitAllowance
			totAllowances
			hours
			minutes
			hoursOvertime
			minutesOvertime1
			minutesOvertime
			minutesOvertime2
			hoursOvertime1
			hoursOvertime2
			hoursTransportTimePaid
			minutesTransportTimePaid
			hoursNight
			minutesNight
			minutesTooEarly
			hoursTooEarly
			mileageToSetAllowance
			timesheet {
				id
				strDate
				endDate
				week
				totMin
				validated
				comment
				salary
				totMinOvertime
				totMinNight
				totMinTooEarly
				kgCoTwo
				totMinOvertime1
				totMinOvertime2
				transportTimePaid
				hasNotSubmittedDay
				weeklyTotalOvertime
				weeklyTotalOvertimeStr
				weeklyTotMinOvt
				weeklyTotMinOvt1
				weeklyTotMinOvt2
				weeklySalaryOvt
				weeklySalaryOvt1
				weeklySalaryOvt2
				weeklyCarAllowance
				weeklyComputerAllowance
				weeklyProductionFeeAllowance
				weeklyPhoneAllowance
				weeklyBoxKitAllowance
				dailyTotAllowances
				weeklyTotAllowances
				weeklyTotPerDiem
				hours
				minutes
				hoursOvertime
				minutesOvertime
				hoursOvertime1
				hoursOvertime2
				minutesOvertime1
				minutesOvertime2
				hoursTransportTimePaid
				minutesTransportTimePaid
				hoursNight
				minutesNight
				hoursTooEarly
				minutesTooEarly
				department
				departmentName
				function
				functionName
				weeklyTotMinOvtStr
				weeklyTotMinOvt1Str
				weeklyTotMinOvt2Str
				weeklyLunchPerDiem
				weeklyHotelPerDiem
				weeklyDinnerPerDiem
				weeklyAbroadPerDiem
				encoderId
				encoder {
					id
					name
					firstName
					fullName
				}
			}
			user {
				id
				name
				firstName
			}
			encoderId
			encoder {
				id
				name
				firstName
				fullName
			}
			contract {
				useDinnerPerDiem
				useAbroadPerDiem
				useLunchPerDiem
				useHotelPerDiem
				useCarAllowance
				useProductionFeeAllowance
				useComputerAllowance
				usePhoneAllowance
				useBoxKitAllowance
				carAllowanceRate
				productionFeeAllowanceRate
				computerAllowanceRate
				phoneAllowanceRate
				boxKitAllowanceRate
				hotelPerDiem
				lunchPerDiem
				dinnerPerDiem
				abroadPerDiem
			}
		}
	}
`;

const mutation_validateTimesheet = gql`
	mutation ($TimesheetId: ID!, $Comment: String) {
		ValidateTimesheet(TimesheetId: $TimesheetId, Comment: $Comment) {
			id
		}
	}
`;

const mutation_rejectTimesheet = gql`
	mutation ($TimesheetId: ID!, $Comment: String) {
		RejectTimesheet(TimesheetId: $TimesheetId, Comment: $Comment) {
			id
		}
	}
`;

const mutation_rejectTsDay = gql`
	mutation ($TsDayId: ID!, $Comment: String) {
		RejectTsDay(TsDayId: $TsDayId, Comment: $Comment) {
			id
		}
	}
`;

const mutation_validateTsDay = gql`
	mutation ($TsDayId: ID!) {
		ValidateTsDay(TsDayId: $TsDayId) {
			id
		}
	}
`;

const mutation_undoRejectTsDay = gql`
	mutation ($TsDayId: ID!) {
		UndoRejectTsDay(TsDayId: $TsDayId) {
			id
		}
	}
`;

export default {
	name: 'ValidateTSDetails',
	mixins: [languageMessages, globalMixin, isSingleProjectMixin],
	props: {
		hover: {
			type: Boolean,
			default: true
		},
		striped: {
			type: Boolean,
			default: true
		},
		bordered: {
			type: Boolean,
			default: true
		},
		small: {
			type: Boolean,
			default: false
		},
		fixed: {
			type: Boolean,
			default: false
		}
	},
	components: {
		Loading,
		MobileValidateTsDetailsList,
		ScriptExportModal,
		MessageCircle,
		Button
	},
	data: () => {
		return {
			hv: 'dark',
			Validated: 0,
			erreur: {},
			rejectComment: '',
			curTs: {
				id: 0,
				strDate: '',
				endDate: '',
				hours: '',
				minutes: '',
				hoursOvertime: '',
				minutesOvertime: '',
				hoursNight: '',
				minutesNight: '',
				salary: 0,
				validated: 0,
				validatedStatus: 'Not Submited',
				hhmm: '',
				hhmmOvt: '',
				hhmmOvt1: '',
				hhmmOvt2: '',
				hhmmTransport: '',
				hhmmNight: '',
				hhmmTooEarly: '',
				comment: ''
			},
			curTsDayRejected: 0,
			curMonthStr: '',
			TsDays: [],
			currentPage: 1,
			perPage: 0,
			warning: '',
			successModal: false,
			successModalValidated: false,
			successModalRejected: false,
			successModalRejectedTsDay: false,
			successModalRejectTimesheet: false,
			filter: '',
			isLoading: false,
			timeZoneStr: '',
			timeZoneHourDif: 0,
			curDay: {
				id: 0,
				date: '',
				strTime: 'T08:00:00Z',
				endTime: 'T18:00:00Z',
				totMin: 0,
				lunchStrTime: 'T00:00:00Z',
				lunchEndTime: 'T00:00:00Z',
				lunch: false,
				hotel: false,
				stageDayUsed: false,
				validated: 0,
				validatedStatus: 'Not Submited'
			},
			isScriptTimeSheetReportOpen: false,
			scriptTypeId: 10,
			scripts: [],
			isLoadingSendMail: false,
			customReport: null,
			isLoadingDownload: false,

			showGridDetails: false
		};
	},
	computed: {
		canSeeSalary() {
			// alert(!store.state.myProject.hideSalaryInfo || store.canViewGlobalBudget());
			return !store.state.myProject.hideSalaryInfo || store.canViewGlobalBudget();
		},
		tsFields() {
			let flds = [];
			const statusFld = {
				key: 'validatedStatus',
				label: this.FormMSG(40, 'Status'),
				sortable: false
			};
			// console.log("isfilmsingle:",this.isFilmSingle)

			flds.push(statusFld);

			const fldsBase = [
				{
					key: 'date',
					label: this.FormMSG(41, 'Date'),
					formatter: (value) => {
						return new moment(value).utc().format('DD/MM/YYYY');
					},
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'strTime',
					label: this.FormMSG(42, 'Start'),
					formatter: (value) => {
						return value.split('T')[1].substring(0, 5);
					},
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'dayType',
					label: this.FormMSG(581, 'Type'),
					formatter: (value, key, item) => {
						return this.getFieldValueByShowGridDetails(this.showGridDetails, value, item.salaryBase);
					},
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'endTime',
					label: this.FormMSG(43, 'End'),
					formatter: (v, z, root) => {
						const time = root.endTime.split('T')[1].substring(0, 5);
						return root.endTime.substring(8, 10) == root.strTime.substring(8, 10) ? time : time + ' (' + root.endTime.substring(8, 10) + ')';
					},
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'hhmm',
					label: this.FormMSG(46, 'Hours'),
					sortable: false,
					formatter: (value, key, item) => {
						let separator = this.$screen.width < 992 ? ' | ' : '<br>';
						return !this.showGridDetails ? item.hhmm : item.hhmm + separator + rendCurrency(item.salaryBase);
					},
					class: 'text-center'
				}
			];
			flds = flds.concat(fldsBase);

			const ovtFld = {
				key: 'hhmmOvt',
				label: this.FormMSG(47, 'Ovt'),
				formatter: (v, z, root) => {
					let separator = this.$screen.width < 992 ? ' | ' : '<br>';
					return !this.showGridDetails ? root.hhmmOvt : root.hhmmOvt + separator + rendCurrency(root.salaryOvertime);
				},
				class: 'text-center'
			};
			if (this.showOvt()) {
				flds.push(ovtFld);
			}

			const ovt1Fld = {
				key: 'hhmmOvt1',
				label: this.FormMSG(137, 'Ovt1'),
				formatter: (v, z, root) => {
					let separator = this.$screen.width < 992 ? ' | ' : '<br>';
					return !this.showGridDetails ? root.hhmmOvt1 : root.hhmmOvt1 + separator + rendCurrency(root.salaryOvertime1);
				},
				class: 'text-center'
			};
			if (this.showOvt1()) {
				flds.push(ovt1Fld);
			}

			const ovt2Fld = {
				key: 'hhmmOvt2',
				label: this.FormMSG(138, 'Ovt2'),
				formatter: (v, z, root) => {
					let separator = this.$screen.width < 992 ? ' | ' : '<br>';
					return !this.showGridDetails ? root.hhmmOvt2 : root.hhmmOvt2 + separator + rendCurrency(root.salaryOvertime2);
				},
				class: 'text-center'
			};
			if (this.showOvt2()) {
				flds.push(ovt2Fld);
			}

			const transportFld = {
				key: 'hhmmTransport',
				label: this.FormMSG(139, 'Transport'),
				formatter: (v, z, root) => {
					let separator = this.$screen.width < 992 ? ' | ' : '<br>';
					return !this.showGridDetails ? root.hhmmTransport : root.hhmmTransport + separator + rendCurrency(root.salaryTransport);
				},
				class: 'text-center'
			};
			if (this.showTransport()) {
				flds.push(transportFld);
			}

			const nightFld = {
				key: 'hhmmNight',
				label: this.FormMSG(55, 'Night'),
				formatter: (v, z, root) => {
					let separator = this.$screen.width < 992 ? ' | ' : '<br>';
					return !this.showGridDetails ? root.hhmmNight : root.hhmmNight + separator + rendCurrency(root.salaryHourNight);
				},
				class: 'text-center'
			};
			if (this.showNightHours()) {
				flds.push(nightFld);
			}

			const tooEarlyHoursFld = {
				key: 'hhmmTooEarly',
				label: this.FormMSG(48, 'Anticipated'),
				formatter: (v, z, root) => {
					let separator = this.$screen.width < 992 ? ' | ' : '<br>';
					return !this.showGridDetails ? root.hhmmTooEarly : root.hhmmTooEarly + separator + rendCurrency(root.salaryHourTooEarly);
				},
				class: 'text-center'
			};
			if (this.showTooEarlyHours()) {
				flds.push(tooEarlyHoursFld);
			}

			const lunchflds = [
				{
					key: 'lunchStrTime',
					label: this.FormMSG(44, 'Lunch start'),
					formatter: (v, z, root) => {
						const time = root.lunchStrTime.split('T')[1].substring(0, 5);
						return root.lunchEndTime.substring(11, 15) == root.lunchStrTime.substring(11, 15)
							? ''
							: root.date.substring(8, 10) == root.lunchStrTime.substring(8, 10)
							? time
							: time + ' (' + root.lunchStrTime.substring(8, 10) + ')';
					},
					sortable: false,
					class: 'text-center'
				},
				{
					key: 'lunchEndTime',
					label: this.FormMSG(45, 'Lunch end'),
					formatter: (v, z, root) => {
						const time = root.lunchEndTime.split('T')[1].substring(0, 5);
						//console.log("in formatter:",root)
						return root.lunchEndTime.substring(11, 15) == root.lunchStrTime.substring(11, 15)
							? ''
							: root.date.substring(8, 10) == root.lunchEndTime.substring(8, 10)
							? time
							: time + ' (' + root.lunchEndTime.substring(8, 10) + ')';
					},
					sortable: false,
					class: 'text-center'
				}
			];
			if (this.showLunch()) {
				flds = flds.concat(lunchflds);
			}

			const lunchPd = {
				key: 'lunch',
				label: this.FormMSG(141, 'Per Diem'),
				sortable: false,
				class: 'text-center',
				isSlot: true
			};
			if (this.showLunchPerDiem() || this.showHotelPerDiem() || this.showDinnerPerDiem() || this.showAbroadPerDiem()) {
				flds.push(lunchPd);
			}

			const HotelPd = {
				key: 'hotel',
				label: this.FormMSG(142, 'Allowances'),
				sortable: false,
				class: 'text-center',
				isSlot: true
			};
			// if (this.showHotelPerDiem()) {
			flds.push(HotelPd);
			const flds1 = [
				{
					key: 'dayRate',
					label: this.FormMSG(50, 'Rate'),
					formatter: (value) => {
						return value + ' %';
					},
					sortable: false,
					class: 'text-center'
				},
				{
					key: 'kgCoTwo',
					label: 'CO2',
					formatter: (v) => rendKgCo2(v),
					sortable: true,
					class: 'text-right'
				},
				{
					key: 'remark',
					label: this.FormMSG(5001, 'Comment'),
					sortable: false,
					class: 'text-center'
				},
				{
					key: 'rej',
					label: this.FormMSG(53, 'Refuse'),
					formatter: (v) => v,
					class: 'text-center',
					isSlot: true
				}
			];
			const salary = [
				{
					key: 'salary',
					label: this.FormMSG(51, 'Salary'),
					formatter: (value) => rendCurrency(value),
					sortable: false,
					class: 'text-right'
				}
			];

			if (!store.state.myProject.hideSalaryInfo || store.canViewGlobalBudget()) {
				flds1.splice(2, 0, ...salary);
			}
			flds = flds.concat(flds1);

			return flds;
		},
		tsMobileFields() {
			const keys = ['validatedStatus'];
			return this.tsFields.filter((t) => !keys.includes(t.key));
		},
		caption2() {
			const strFrom = moment(this.curTs.strDate).format('DD/MM/YYYY');

			const strTo = moment(this.curTs.endDate).format('DD/MM/YYYY');

			// return this.FormMSG(10, 'Timesheet details') + strFrom + strTo
			return strFrom + ' - ' + strTo;
		},
		encodedBy() {
			return `${this.curTs.encoder.firstName} ${this.curTs.encoder.name}`.toUpperCase();
		},
		styleObject: function () {
			var myObj = {
				color: 'black',
				fontSize: '14px'
			};
			if (this.curTs.validated == 1) {
				myObj.color = 'blue';
			} else if (this.curTs.validated == 2) {
				myObj.color = 'yellow';
			} else if (this.curTs.validated == 8) {
				myObj.color = 'green';
			} else if (this.curTs.validated == 4 || this.curTs.validated == 16) {
				myObj.color = 'red';
			}
			return myObj;
		},
		useEntryForCrewTimesheet() {
			return store.state.myProject.useEntryForCrewTimesheet;
		}
	},
	methods: {
		...mapActions({
			getNotifications: 'header/getNotifications'
		}),
		rowClicked(item) {
			this.$router.push(`/timesheet?date=${item.date}`);
		},
		async validateTsDay(id) {
			const TsDayId = parseInt(id, 10);
			//console.log("in finalize day validate id:", TsDayId);
			await this.$apollo
				.mutate({
					mutation: mutation_validateTsDay,
					variables: {
						TsDayId
					}
				})
				.then(async (result) => {
					await this.reloadData();
				})
				.catch((error) => {
					console.log(error);
					this.erreur = error;
				});
		},
		async undoRejectTsDay(id) {
			var TsDayId = parseInt(id, 10);
			//console.log("in finalize day validate id:", TsDayId);
			await this.$apollo
				.mutate({
					mutation: mutation_undoRejectTsDay,
					variables: {
						TsDayId
					}
				})
				.then(async (result) => {
					await this.getNotifications();
					await this.reloadData();
				})
				.catch((error) => {
					console.log(error);
					this.erreur = error;
				});
		},
		rejectTsDay(id) {
			this.curTsDayRejected = id;
			this.rejectComment = '';
			this.successModalRejectedTsDay = true;
		},
		canNotValidateTs() {
			// if one item is refused, cannot validate ts
			return false;
		},
		async finaliseTsDayReject() {
			this.successModalRejectedTsDay = false;
			var TsDayId = parseInt(this.curTsDayRejected, 10);
			var Comment = this.rejectComment;
			// console.log('in finalize day reject id:', TsDayId)
			// console.log('in finalize day reject comment:', Comment)
			await this.$apollo
				.mutate({
					mutation: mutation_rejectTsDay,
					variables: {
						TsDayId,
						Comment
					}
				})
				.then(async (result) => {
					await this.getNotifications();
					await this.reloadData();
					this.rejectComment = '';
					this.curTsDayRejected = 0;
					this.successModalRejected = true;
				})
				.catch((error) => {
					console.log(error);
					this.erreur = error;
				});
		},
		async validateTimesheet() {
			var TimesheetId = parseInt(this.$route.params.id, 10);
			var Comment = '';
			await this.$apollo
				.mutate({
					mutation: mutation_validateTimesheet,
					variables: {
						TimesheetId,
						Comment
					}
				})
				.then(async (result) => {
					await this.getNotifications();
					await this.reloadData();
					this.successModalValidated = true;
				})
				.catch((error) => {
					console.log(error);
					this.erreur = error;
				});
		},
		async finaliseTimesheetReject() {
			this.successModalRejectTimesheet = false;
			var TimesheetId = parseInt(this.$route.params.id, 10);
			var Comment = this.rejectComment;
			// console.log('in finalize timesheet reject id:', TimesheetId)
			// console.log('in finalize timesheet reject comment:', Comment)
			await this.$apollo
				.mutate({
					mutation: mutation_rejectTimesheet,
					variables: {
						TimesheetId,
						Comment
					}
				})
				.then(async (result) => {
					await this.getNotifications();
					await this.reloadData();
					this.rejectComment = '';
					this.successModalRejected = true;
					this.$router.go(-1);
				})
				.catch((error) => {
					console.log(error);
					this.erreur = error;
				});
		},
		getCurrentDate() {
			var currentDateWithFormat = new Date().toJSON().slice(0, 10);
			return currentDateWithFormat;
		},
		firstTimeInitialisation() {
			this.setTimeZoneStr();
			this.curTs = store.getCurTimesheet();
			this.curDay.date = this.getCurrentDate();
			this.curMonthStr = this.curDay.date.substring(0, 7);
		},
		updateTimeToTZ(timeString) {
			this.setTimeZoneStr();
			if (timeString.length == 0) {
				return timeString;
			}
			// check if already converted
			//  console.log("in updateTimeToTZ  RECEIVING:",timeString );
			//console.log("substring 9=",timeString.substring(9) );
			//console.log("in updateTimeToTZ timeZoneStr=",this.timeZoneStr);

			if (timeString.substring(9) != this.timeZoneStr) {
				// needs conversion
				//console.log("in updateTimeToTZ inval=",timeString)
				// extract time value
				var timStr = timeString.substring(1, 3);
				var hGmt = parseInt(timStr, 10);
				var h = (hGmt + this.timeZoneHourDif) % 24;
				if (h > 9) {
					var retval = 'T' + h + timeString.substring(3);
				} else {
					var retval = 'T0' + h + timeString.substring(3);
				}
				//    console.log("in updateTimeToTZ retval=",retval);
				retval = retval.substring(0, 9) + this.timeZoneStr;
				//    console.log("in updateTimeToTZ retval new TZ=",retval);
				return retval;
			} else {
				// does not need conversion
				var retval = timeString;
				//    console.log("in updateTimeToTZ retval kept existing conversion=",retval);
				return retval;
			}
		},
		updateTZdataEach(timeData) {
			// setting row color
			let totDailyMin = parseInt(timeData.minutesOvertime) + parseInt(timeData.minutesOvertime1) + parseInt(timeData.minutesOvertime2);
			let totDailyHrs = parseInt(timeData.hoursOvertime) + parseInt(timeData.hoursOvertime1) + parseInt(timeData.hoursOvertime2);
			timeData.validatedStatus = this.validatedText(timeData.validated);
			// https://bootstrap-vue.js.org/docs/reference/color-variants
			//timeData._rowVariant = this.validatedColor(timeData.validated);
			timeData.validatedClass = this.validatedColor(timeData.validated);
			timeData.statusClass = classStatus(timeData.validated);
			timeData.strTime = moment(timeData.strTime).format();
			timeData.endTime = moment(timeData.endTime).format();
			timeData.lunchStrTime = moment(timeData.lunchStrTime).format();
			timeData.lunchEndTime = moment(timeData.lunchEndTime).format();
			timeData.leftHomeAt = moment(timeData.leftHomeAt).format();
			timeData.returnedHomeAt = moment(timeData.returnedHomeAt).format();
			timeData.hhmm = timeData.hours + ':' + timeData.minutes;
			timeData.hhmmOvt = timeData.hoursOvertime + ':' + timeData.minutesOvertime;
			timeData.hhmmNight = timeData.hoursNight + ':' + timeData.minutesNight;
			timeData.hhmmTooEarly = timeData.hoursTooEarly + ':' + timeData.minutesTooEarly;
			timeData.hhmmOvt1 = timeData.hoursOvertime1 + ':' + timeData.minutesOvertime1;
			timeData.hhmmOvt2 = timeData.hoursOvertime2 + ':' + timeData.minutesOvertime2;
			timeData.tothhmmOvt = totDailyHrs + ':' + totDailyMin;
			timeData.hhmmTransport = timeData.hoursTransportTimePaid + ':' + timeData.minutesTransportTimePaid;
			timeData.hotelPerDiem = timeData.contract.hotelPerDiem;
			timeData.lunchPerDiem = timeData.contract.lunchPerDiem;
			timeData.dinnerPerDiem = timeData.contract.dinnerPerDiem;
			timeData.abroadPerDiem = timeData.contract.abroadPerDiem;
		},
		setTimeZoneStr() {
			// getting timezone
			if (this.timeZoneStr.length > 0) {
				return;
			}
			var d = new Date();
			var n = d.getTimezoneOffset();
			if (n < 0) {
				var hOffset = -n / 60;
				var sign = '+';
			} else {
				var hOffset = n / 60;
				var sign = '-';
			}
			var BASE = '00';
			this.timeZoneStr = hOffset ? sign + BASE.substr(0, 2 - Math.ceil(hOffset / 10)) + hOffset : sign + BASE;
			this.timeZoneStr = this.timeZoneStr + ':00';
			this.timeZoneHourDif = hOffset;
		},
		validatedText(validated) {
			// returns the label text corresponding to the validated value
			return this.GetTextFromMenuNumberAndMenuValue(1008, validated);
		},
		validatedColor(validated) {
			if (validated == 1) {
				var retval = 'primary';
			} else if (validated == 2) {
				var retval = 'warning';
			} else if (validated == 8) {
				var retval = 'success';
			} else if (validated == 4 || validated == 16) {
				var retval = 'danger';
			} else {
				var retval = 'info';
			}
			return retval;
		},
		async reloadData() {
			var TimesheetId = parseInt(this.$route.params.id, 10);
			await this.$apollo
				.query({
					query: query_tsdays,
					variables: {
						TimesheetId,
						UnLoadNotSubmittedDay: true
					},
					// options: { fetchPolicy: 'network-only' },
					// options: { fetchPolicy: 'no-cache' },
					// force update from server
					fetchPolicy: 'network-only'
				})
				.then((result) => {
					result.data.TimesheetDetails.forEach(this.updateTZdataEach);
					this.TsDays = result.data.TimesheetDetails;
					//console.log("In reloaddata:", this.TsDays);

					if (this.TsDays.length > 0) {
						let curTs = this.TsDays[0].timesheet;
						// console.log({curTs})
						curTs.contract = this.TsDays[0].contract;
						this.updateTZdataEach(curTs);
						this.curTs = {
							...this.curTs,
							curTs
						};
						this.curTs.comment = this.TsDays[0].timesheet.comment;
						// console.log('this.curTs:', result.data.TimesheetDetails)
					}
				})
				.catch((error) => {
					console.log(error);
					this.erreur = error;
				});
		},
		async sendXlsByEmail() {
			this.isLoadingSendMail = true;
			this.warning = '';
			this.scripts = await getScripts(store.state.myProject.licenseID, store.state.myProject.id, this.scriptTypeId, true);
			const id = +this.curTs.id;

			if (this.scripts.length === 0) {
				await this.manageTimesheetReport(id, { id: 0 }, true).then(() => {
					this.createToastForMobile(this.FormMSG(33, 'Success'), this.FormMSG(56, 'The document has been sent to your email address.'));
				});
			} else if (this.scripts.length === 1) {
				await this.manageTimesheetReport(id, { id: this.scripts[0].id }, true).then(() => {
					this.createToastForMobile(this.FormMSG(33, 'Success'), this.FormMSG(56, 'The document has been sent to your email address.'));
				});
			} else {
				this.isScriptTimeSheetReportOpen = true;
				this.customReport = {
					subjectId: id,
					sendEmail: true
				};
			}
		},
		async downloadXls() {
			this.isLoadingDownload = true;
			this.warning = '';
			this.scripts = await getScripts(store.state.myProject.licenseID, store.state.myProject.id, this.scriptTypeId, true);
			const id = +this.curTs.id;

			if (this.scripts.length === 0) {
				await this.manageTimesheetReport(id).finally(() => {
					this.isLoadingDownload = false;
				});
			} else if (this.scripts.length === 1) {
				await this.manageTimesheetReport(id, {
					id: this.scripts[0].id,
					fileName: this.scripts[0].customExportFileName,
					ext: this.getExtensionScript(
						OUTPUT_FORMAT_EXPORT.at(this.scripts[0].outputFileFormat),
						this.scripts[0].customExtension,
						getFileExtension(this.scripts[0].fileName)
					)
				}).finally(() => {
					this.isLoadingDownload = false;
				});
			} else {
				this.isScriptTimeSheetReportOpen = true;
				this.customReport = {
					subjectId: id,
					sendEmail: false
				};
			}
		},
		async manageTimesheetReport(subjectId, script = { id: 0, fileName: null, ext: 'xlsx' }, sendEmail = false) {
			let fileName = this.getFileNameScript(FILENAME_REPORT.REPORT_TS, script.id, script.fileName);

			await timeSheetReport(
				{
					subjectId,
					sendEmail,
					activeStatus: true
				},
				script.id
			)
				.then((result) => {
					if (!sendEmail) {
						this.forceDownload(result, fileName + '.' + script.ext);
					}
				})
				.catch((e) => {
					console.error(e);
				})
				.finally(() => (this.isLoadingSendMail = false));
		},
		displayCurrency(value) {
			return rendCurrency(value);
		},
		displayKgCoTwo(value) {
			return rendKgCo2(value);
		},
		showNightHours() {
			return store.state.myProject.useNightHours;
		},
		showTooEarlyHours() {
			return store.state.myProject.useTooEarlyHours;
		},
		showOvt() {
			return store.state.myProject.useOvertime;
		},
		showOvt1() {
			return store.state.myProject.useOvt1;
		},
		showOvt2() {
			return store.state.myProject.useOvt2;
		},
		showTransport() {
			//console.log("in show transport:",store.state.myProject);
			return store.state.myProject.useHomeToWorkTime;
		},
		showLunch() {
			return store.state.myProject.useLunchTime;
		},
		showLunchPerDiem() {
			return store.state.myProject.useLunchPerDiem && !this.curDay.stageDayUsed;
		},
		showHotelPerDiem() {
			return store.state.myProject.useHotelPerDiem;
		},
		showWeeklyOvt() {
			return store.state.myProject.useWeeklyOvt;
		},
		showWeeklyOvt1() {
			return store.state.myProject.useWeeklyOvt1;
		},
		showWeeklyOvt2() {
			return store.state.myProject.useWeeklyOvt2;
		},
		showDinnerPerDiem() {
			return store.state.myProject.useDinnerPerDiem;
		},
		showAbroadPerDiem() {
			return store.state.myProject.useAbroadPerDiem;
		},
		tooltipContentPerIdem(item) {
			let content = [];
			if (item.hotel && item.hotelPerDiem > 0) {
				content.push(this.FormMSG(103, 'Hotel: ') + this.displayCurrency(item.hotelPerDiem));
			}

			if (item.lunch && item.lunchPerDiem > 0) {
				content.push(this.FormMSG(104, 'Lunch: ') + this.displayCurrency(item.lunchPerDiem));
			}

			if (item.useDinner && item.dinnerPerDiem > 0) {
				content.push(this.FormMSG(105, 'Dinner: ') + this.displayCurrency(item.dinnerPerDiem));
			}

			if (item.useAbroad && item.abroadPerDiem > 0) {
				content.push(this.FormMSG(110, 'Abroad: ') + this.displayCurrency(item.abroadPerDiem));
			}

			return content.join(', ');
		},
		tooltipContentAllowance(item) {
			let content = [];
			if (item.carAllowance > 0) {
				content.push(
					this.FormMSG(143, 'Car ') + '(' + this.rendAllowanceType(item.contract.carAllowanceRate) + '): ' + this.displayCurrency(item.carAllowance)
				);
			}

			if (item.computerAllowance > 0) {
				content.push(
					this.FormMSG(144, 'Computer ') +
						'(' +
						this.rendAllowanceType(item.contract.computerAllowanceRate) +
						'): ' +
						this.displayCurrency(item.computerAllowance)
				);
			}

			if (item.phoneAllowance > 0) {
				content.push(
					this.FormMSG(145, 'Phone ') +
						'(' +
						this.rendAllowanceType(item.contract.phoneAllowanceRate) +
						'): ' +
						this.displayCurrency(item.phoneAllowance)
				);
			}

			if (item.productionFeeAllowance > 0) {
				content.push(
					this.FormMSG(146, 'Production fee ') +
						'(' +
						this.rendAllowanceType(item.contract.productionFeeAllowanceRate) +
						'): ' +
						this.displayCurrency(item.productionFeeAllowance)
				);
			}

			if (item.boxKitAllowance > 0) {
				content.push(
					this.FormMSG(147, 'Box kit ') +
						'(' +
						this.rendAllowanceType(item.contract.boxKitAllowanceRate) +
						'): ' +
						this.displayCurrency(item.boxKitAllowance)
				);
			}

			if (item.mileageToSetAllowance > 0) {
				content.push(this.FormMSG(587, 'Mileage to set') + ': ' + item.mileageToSetAllowance);
			}

			return content.join(', ');
		},
		rendAllowanceType(type) {
			if (type == 0) {
				return this.FormMSG(148, 'D');
			} else if (type == 1) {
				return this.FormMSG(149, 'W');
			} else if (type == 2) {
				return this.FormMSG(150, 'H');
			}
		},
		timeSheetRerpotOnClosed() {
			this.isScriptTimeSheetReportOpen = false;
			this.isLoadingSendMail = false;
			this.isLoadingDownload = false;
		},
		handleLoading(payload) {
			if (payload) {
				this.isLoadingSendMail = false;
				this.isLoadingDownload = false;
			}
		}
	},
	async created() {
		if (this.curDay.date.length === 0) {
			this.firstTimeInitialisation();
		}
		await this.reloadData();
	}
};
</script>

<style lang="scss" scoped>
.date-title {
	font-weight: 500;
	font-size: 1.75rem;
	color: rgba(6, 38, 62, 0.74);
}
.name-title {
	// color: #3c7442;
	font-size: 14px;
	font-weight: 500;
	color: #06263e;
}
.title-ts-detail {
	border-bottom: 0.005rem solid rgba(226, 230, 235, 0.85);
	margin-left: -12px;
	margin-right: -12px;
}
.border-encoded {
	border: 2px solid #cad0d7;
	border-radius: 5px;
}
.card >>> table > tbody > tr > td {
	cursor: pointer;
}
</style>
