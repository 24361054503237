var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.erreur, warning: _vm.warning } },
    [
      _c("transition", { attrs: { name: "slide" } }, [
        _c(
          "div",
          {
            staticClass: "p-0",
            class: {
              "container-layout": _vm.$screen.width >= 992,
              "container-mobile": _vm.$screen.width < 992,
            },
          },
          [
            _c(
              "b-row",
              { staticClass: "form" },
              [
                _c(
                  "b-col",
                  { attrs: { cols: "12", xl: "12" } },
                  [
                    _c(
                      "h1",
                      {
                        class: [
                          `${
                            !_vm.$screen.sm && !_vm.$screen.md
                              ? "main-page-title"
                              : ""
                          }`,
                          { "is-pwa": _vm.$isPwa() },
                        ],
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(10, "Timesheet details")) +
                            "\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                    _c(
                      "b-card",
                      {
                        staticStyle: { "background-color": "#fff" },
                        attrs: { "no-body": "" },
                      },
                      [
                        _vm.$screen.width >= 992
                          ? _c(
                              "b-row",
                              {
                                staticClass:
                                  "back-with-title cursor-pointer d-flex align-items-center pt-0 pb-2 pl-0",
                                class: { "mt-16": _vm.$isPwa() },
                              },
                              [
                                _c("b-col", { attrs: { cols: "6" } }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn-transparent",
                                      on: {
                                        click: function ($event) {
                                          return _vm.$router.push(
                                            "/mytimesheets"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(_vm.getLucideIcon("ArrowLeft"), {
                                        tag: "component",
                                        staticClass: "icon",
                                        attrs: {
                                          color: "rgba(6, 38, 62, 0.65)",
                                          size: 22,
                                        },
                                      }),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "title-back-bolder mx-0",
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.FormMSG(
                                                  20,
                                                  "Back to timesheets"
                                                )
                                              ) +
                                              "\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _c(
                                  "b-col",
                                  {
                                    staticClass: "inline-flex text-right",
                                    attrs: { cols: "6" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "date-title pr-3" },
                                      [_vm._v(_vm._s(this.caption2))]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "name-title pr-3" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.curTs.fullName) + " "
                                        ),
                                        _c("span", {
                                          staticClass: "divider-dot",
                                        }),
                                        _vm._v(
                                          " " + _vm._s(_vm.curTs.functionName)
                                        ),
                                      ]
                                    ),
                                    _vm.useEntryForCrewTimesheet
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex align-items-center justify-content-end w-100 pr-3",
                                          },
                                          [
                                            !_vm.isFilmSingle
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "border-encoded p-1",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          149,
                                                          "Encoded by: "
                                                        )
                                                      ) + " "
                                                    ),
                                                    _c("b", [
                                                      _vm._v(
                                                        _vm._s(_vm.encodedBy)
                                                      ),
                                                    ]),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _c("div", [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "back-with-title d-flex align-items-center cursor-pointer",
                                  class: { "mt-16": _vm.$isPwa() },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$router.go(-1)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "icon" },
                                    [
                                      _c(_vm.getLucideIcon("ArrowLeft"), {
                                        tag: "component",
                                        attrs: { color: "#667987", size: 22 },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("h2", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.FormMSG(20, "Back to timesheets")
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "title-ts-detail text-center py-2",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "date-title pr-3" },
                                    [_vm._v(_vm._s(this.caption2))]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "name-title pr-3" },
                                    [
                                      _vm._v(_vm._s(_vm.curTs.fullName) + " "),
                                      _c("span", {
                                        staticClass: "divider-dot",
                                      }),
                                      _vm._v(
                                        " " + _vm._s(_vm.curTs.functionName)
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                        _c(
                          "b-card-text",
                          {
                            class: `${
                              _vm.$screen.width >= 992
                                ? "px-3 pt-4"
                                : "px-2 pt-3"
                            }`,
                          },
                          [
                            _c(
                              "div",
                              {
                                class: `${
                                  _vm.$screen.width >= 992
                                    ? "card card-border-blue-light"
                                    : ""
                                }`,
                              },
                              [
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { sm: "4" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label: _vm.FormMSG(13, "Hours:"),
                                              "label-for": "hours",
                                            },
                                          },
                                          [
                                            _c("b-form-input", {
                                              attrs: {
                                                disabled: true,
                                                type: "text",
                                                id: "hours",
                                              },
                                              model: {
                                                value: _vm.curTs.hhmm,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.curTs,
                                                    "hhmm",
                                                    $$v
                                                  )
                                                },
                                                expression: "curTs.hhmm",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { sm: "4" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label: "CO2",
                                              "label-for": "kgCoTwo",
                                            },
                                          },
                                          [
                                            _c("b-form-input", {
                                              attrs: {
                                                disabled: true,
                                                value: _vm.displayKgCoTwo(
                                                  _vm.curTs.kgCoTwo
                                                ),
                                                type: "text",
                                                id: "kgCoTwo",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm.canSeeSalary
                                      ? _c(
                                          "b-col",
                                          { attrs: { sm: "4" } },
                                          [
                                            _c(
                                              "b-form-group",
                                              {
                                                attrs: {
                                                  label: _vm.FormMSG(
                                                    17,
                                                    "Salary:"
                                                  ),
                                                  "label-for": "salary",
                                                },
                                              },
                                              [
                                                _c("b-form-input", {
                                                  attrs: {
                                                    disabled: true,
                                                    value: _vm.displayCurrency(
                                                      _vm.curTs.salary
                                                    ),
                                                    type: "text",
                                                    id: "salary",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _vm.showOvt() ||
                                _vm.showOvt1() ||
                                _vm.showOvt2()
                                  ? _c(
                                      "b-row",
                                      { staticClass: "ml-0 w-100" },
                                      [
                                        _c(
                                          "b-col",
                                          {
                                            staticClass: "px-0",
                                            attrs: { cols: "12" },
                                          },
                                          [
                                            _c(
                                              "fieldset",
                                              {
                                                staticClass:
                                                  "scheduler-border border-groove-blue-streak w-100 pb-3",
                                              },
                                              [
                                                _c(
                                                  "legend",
                                                  {
                                                    staticClass:
                                                      "scheduler-border text-color-blue-streak",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            140,
                                                            "Daily Overtime"
                                                          )
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "b-row",
                                                  [
                                                    _vm.showOvt()
                                                      ? _c(
                                                          "b-col",
                                                          {
                                                            attrs: { sm: "4" },
                                                          },
                                                          [
                                                            _c(
                                                              "b-form-group",
                                                              {
                                                                attrs: {
                                                                  label:
                                                                    _vm.FormMSG(
                                                                      14,
                                                                      "Overtime:"
                                                                    ),
                                                                  "label-for":
                                                                    "ovt",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "b-form-input",
                                                                  {
                                                                    attrs: {
                                                                      disabled: true,
                                                                      type: "text",
                                                                      id: "ovt",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .curTs
                                                                          .hhmmOvt,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.curTs,
                                                                            "hhmmOvt",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "curTs.hhmmOvt",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.showOvt1()
                                                      ? _c(
                                                          "b-col",
                                                          {
                                                            attrs: { sm: "4" },
                                                          },
                                                          [
                                                            _c(
                                                              "b-form-group",
                                                              {
                                                                attrs: {
                                                                  label:
                                                                    _vm.FormMSG(
                                                                      121,
                                                                      "Overtime 1:"
                                                                    ),
                                                                  "label-for":
                                                                    "ovt1",
                                                                  "label-cols": 0,
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "b-form-input",
                                                                  {
                                                                    attrs: {
                                                                      disabled: true,
                                                                      type: "text",
                                                                      id: "ovt1",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .curTs
                                                                          .hhmmOvt1,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.curTs,
                                                                            "hhmmOvt1",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "curTs.hhmmOvt1",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.showOvt2()
                                                      ? _c(
                                                          "b-col",
                                                          {
                                                            attrs: { sm: "4" },
                                                          },
                                                          [
                                                            _c(
                                                              "b-form-group",
                                                              {
                                                                attrs: {
                                                                  label:
                                                                    _vm.FormMSG(
                                                                      131,
                                                                      "Overtime 2:"
                                                                    ),
                                                                  "label-for":
                                                                    "ovt2",
                                                                  "label-cols": 0,
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "b-form-input",
                                                                  {
                                                                    attrs: {
                                                                      disabled: true,
                                                                      type: "text",
                                                                      id: "ovt2",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .curTs
                                                                          .hhmmOvt2,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.curTs,
                                                                            "hhmmOvt2",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "curTs.hhmmOvt2",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.showWeeklyOvt() ||
                                _vm.showWeeklyOvt1() ||
                                _vm.showWeeklyOvt2()
                                  ? _c(
                                      "b-row",
                                      { staticClass: "ml-0 w-100" },
                                      [
                                        _c(
                                          "b-col",
                                          {
                                            staticClass: "px-0",
                                            attrs: { cols: "12" },
                                          },
                                          [
                                            _c(
                                              "fieldset",
                                              {
                                                staticClass:
                                                  "scheduler-border border-groove-blue-streak w-100 pb-3",
                                              },
                                              [
                                                _c(
                                                  "legend",
                                                  {
                                                    staticClass:
                                                      "scheduler-border text-color-blue-streak",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            390,
                                                            "Weekly Overtime"
                                                          )
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "b-row",
                                                  [
                                                    _vm.showWeeklyOvt()
                                                      ? _c(
                                                          "b-col",
                                                          {
                                                            attrs: { sm: "4" },
                                                          },
                                                          [
                                                            _c(
                                                              "b-form-group",
                                                              {
                                                                staticStyle: {
                                                                  "font-weight":
                                                                    "bold",
                                                                },
                                                                attrs: {
                                                                  label:
                                                                    _vm.FormMSG(
                                                                      11,
                                                                      "Overtime:"
                                                                    ),
                                                                  "label-for":
                                                                    "ovt",
                                                                  "label-cols": 0,
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "b-form-input",
                                                                  {
                                                                    attrs: {
                                                                      disabled: true,
                                                                      type: "text",
                                                                      id: "ovt",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .curTs
                                                                          .weeklyTotMinOvtStr,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.curTs,
                                                                            "weeklyTotMinOvtStr",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "curTs.weeklyTotMinOvtStr",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.showWeeklyOvt1()
                                                      ? _c(
                                                          "b-col",
                                                          {
                                                            attrs: { sm: "4" },
                                                          },
                                                          [
                                                            _c(
                                                              "b-form-group",
                                                              {
                                                                staticStyle: {
                                                                  "font-weight":
                                                                    "bold",
                                                                },
                                                                attrs: {
                                                                  label:
                                                                    _vm.FormMSG(
                                                                      121,
                                                                      "Overtime 1:"
                                                                    ),
                                                                  "label-for":
                                                                    "ovt1",
                                                                  "label-cols": 0,
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "b-form-input",
                                                                  {
                                                                    attrs: {
                                                                      disabled: true,
                                                                      type: "text",
                                                                      id: "ovt1",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .curTs
                                                                          .weeklyTotMinOvt1Str,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.curTs,
                                                                            "weeklyTotMinOvt1Str",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "curTs.weeklyTotMinOvt1Str",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.showWeeklyOvt2()
                                                      ? _c(
                                                          "b-col",
                                                          {
                                                            attrs: { sm: "4" },
                                                          },
                                                          [
                                                            _c(
                                                              "b-form-group",
                                                              {
                                                                staticStyle: {
                                                                  "font-weight":
                                                                    "bold",
                                                                },
                                                                attrs: {
                                                                  label:
                                                                    _vm.FormMSG(
                                                                      131,
                                                                      "Overtime 2:"
                                                                    ),
                                                                  "label-for":
                                                                    "ovt2",
                                                                  "label-cols": 0,
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "b-form-input",
                                                                  {
                                                                    attrs: {
                                                                      disabled: true,
                                                                      type: "text",
                                                                      id: "ovt2",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .curTs
                                                                          .weeklyTotMinOvt2Str,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.curTs,
                                                                            "weeklyTotMinOvt2Str",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "curTs.weeklyTotMinOvt2Str",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.showTransport() ||
                                _vm.showNightHours() ||
                                _vm.showTooEarlyHours()
                                  ? _c(
                                      "b-row",
                                      [
                                        _c(
                                          "b-col",
                                          { attrs: { sm: "4" } },
                                          [
                                            _vm.showTransport()
                                              ? _c(
                                                  "b-form-group",
                                                  {
                                                    attrs: {
                                                      label: _vm.FormMSG(
                                                        100,
                                                        "Transport:"
                                                      ),
                                                      "label-for": "transport",
                                                      "label-cols": 0,
                                                    },
                                                  },
                                                  [
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        disabled: true,
                                                        type: "text",
                                                        id: "transport",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.curTs
                                                            .hhmmTransport,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.curTs,
                                                            "hhmmTransport",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "curTs.hhmmTransport",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-col",
                                          { attrs: { sm: "4" } },
                                          [
                                            _vm.showNightHours()
                                              ? _c(
                                                  "b-form-group",
                                                  {
                                                    attrs: {
                                                      label: _vm.FormMSG(
                                                        15,
                                                        "Night:"
                                                      ),
                                                      "label-for": "night",
                                                    },
                                                  },
                                                  [
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        disabled: true,
                                                        type: "text",
                                                        id: "night",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.curTs.hhmmNight,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.curTs,
                                                            "hhmmNight",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "curTs.hhmmNight",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-col",
                                          { attrs: { sm: "4" } },
                                          [
                                            _vm.showTooEarlyHours()
                                              ? _c(
                                                  "b-form-group",
                                                  {
                                                    attrs: {
                                                      label: _vm.FormMSG(
                                                        16,
                                                        "Anticipated:"
                                                      ),
                                                      "label-for": "antic",
                                                    },
                                                  },
                                                  [
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        disabled: true,
                                                        type: "text",
                                                        id: "antic",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.curTs
                                                            .hhmmTooEarly,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.curTs,
                                                            "hhmmTooEarly",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "curTs.hhmmTooEarly",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "b-row",
                                  [
                                    _vm.showLunchPerDiem() ||
                                    _vm.showHotelPerDiem() ||
                                    _vm.showDinnerPerDiem() ||
                                    _vm.showAbroadPerDiem()
                                      ? _c(
                                          "b-col",
                                          { attrs: { sm: "6" } },
                                          [
                                            _c(
                                              "b-form-group",
                                              {
                                                staticStyle: {
                                                  "font-weight": "bold",
                                                },
                                              },
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "d-flex align-items-center",
                                                    attrs: { for: "diem" },
                                                  },
                                                  [
                                                    _c("div", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            101,
                                                            "Per Diem:"
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                    _vm.curTs.weeklyTotPerDiem >
                                                    0
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "cursor-pointer ml-1",
                                                            attrs: {
                                                              id: "tooltip-target-1",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              _vm.getLucideIcon(
                                                                "Info"
                                                              ),
                                                              {
                                                                tag: "component",
                                                                attrs: {
                                                                  size: 22,
                                                                  color:
                                                                    "#225cbd",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.curTs.weeklyTotPerDiem >
                                                    0
                                                      ? _c(
                                                          "b-tooltip",
                                                          {
                                                            attrs: {
                                                              target:
                                                                "tooltip-target-1",
                                                              triggers: "hover",
                                                            },
                                                          },
                                                          [
                                                            _vm.curTs
                                                              .weeklyHotelPerDiem >
                                                            0
                                                              ? _c("div", [
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          103,
                                                                          "Hotel: "
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]),
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.displayCurrency(
                                                                          _vm
                                                                            .curTs
                                                                            .weeklyHotelPerDiem
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ])
                                                              : _vm._e(),
                                                            _vm.curTs
                                                              .weeklyLunchPerDiem >
                                                            0
                                                              ? _c("div", [
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          104,
                                                                          "Lunch: "
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]),
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.displayCurrency(
                                                                          _vm
                                                                            .curTs
                                                                            .weeklyLunchPerDiem
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ])
                                                              : _vm._e(),
                                                            _vm.curTs
                                                              .weeklyDinnerPerDiem >
                                                            0
                                                              ? _c("div", [
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          105,
                                                                          "Dinner: "
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]),
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.displayCurrency(
                                                                          _vm
                                                                            .curTs
                                                                            .weeklyDinnerPerDiem
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ])
                                                              : _vm._e(),
                                                            _vm.curTs
                                                              .weeklyAbroadPerDiem >
                                                            0
                                                              ? _c("div", [
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          110,
                                                                          "Abroad: "
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]),
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.displayCurrency(
                                                                          _vm
                                                                            .curTs
                                                                            .weeklyAbroadPerDiem
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ])
                                                              : _vm._e(),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                                _c("b-form-input", {
                                                  attrs: {
                                                    disabled: true,
                                                    value: _vm.displayCurrency(
                                                      _vm.curTs.weeklyTotPerDiem
                                                    ),
                                                    type: "text",
                                                    id: "diem",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "b-col",
                                      { attrs: { sm: "6" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            staticStyle: {
                                              "font-weight": "bold",
                                            },
                                          },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "d-flex align-items-center",
                                                attrs: { for: "allowances" },
                                              },
                                              [
                                                _c("div", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        111,
                                                        "Allowances:"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                                _vm.curTs.dailyTotAllowances >
                                                  0 ||
                                                _vm.curTs.weeklyTotAllowances >
                                                  0
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "cursor-pointer ml-1",
                                                        attrs: {
                                                          id: "tooltip-target-2",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          _vm.getLucideIcon(
                                                            "Info"
                                                          ),
                                                          {
                                                            tag: "component",
                                                            attrs: {
                                                              size: 22,
                                                              color: "#225cbd",
                                                            },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm.curTs.dailyTotAllowances >
                                                  0 ||
                                                _vm.curTs.weeklyTotAllowances >
                                                  0
                                                  ? _c(
                                                      "b-tooltip",
                                                      {
                                                        attrs: {
                                                          target:
                                                            "tooltip-target-2",
                                                          triggers: "hover",
                                                        },
                                                      },
                                                      [
                                                        _vm.curTs
                                                          .weeklyCarAllowance >
                                                        0
                                                          ? _c("div", [
                                                              _c("b", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.FormMSG(
                                                                      112,
                                                                      "Car: "
                                                                    )
                                                                  )
                                                                ),
                                                              ]),
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.displayCurrency(
                                                                      _vm.curTs
                                                                        .weeklyCarAllowance
                                                                    )
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ])
                                                          : _vm._e(),
                                                        _vm.curTs
                                                          .weeklyComputerAllowance >
                                                        0
                                                          ? _c("div", [
                                                              _c("b", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.FormMSG(
                                                                      113,
                                                                      "Computer: "
                                                                    )
                                                                  )
                                                                ),
                                                              ]),
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.displayCurrency(
                                                                      _vm.curTs
                                                                        .weeklyComputerAllowance
                                                                    )
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ])
                                                          : _vm._e(),
                                                        _vm.curTs
                                                          .weeklyPhoneAllowance >
                                                        0
                                                          ? _c("div", [
                                                              _c("b", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.FormMSG(
                                                                      114,
                                                                      "Phone: "
                                                                    )
                                                                  )
                                                                ),
                                                              ]),
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.displayCurrency(
                                                                      _vm.curTs
                                                                        .weeklyPhoneAllowance
                                                                    )
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ])
                                                          : _vm._e(),
                                                        _vm.curTs
                                                          .weeklyProductionFeeAllowance >
                                                        0
                                                          ? _c("div", [
                                                              _c("b", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.FormMSG(
                                                                      115,
                                                                      "Production fee: "
                                                                    )
                                                                  )
                                                                ),
                                                              ]),
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.displayCurrency(
                                                                      _vm.curTs
                                                                        .weeklyProductionFeeAllowance
                                                                    )
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ])
                                                          : _vm._e(),
                                                        _vm.curTs
                                                          .weeklyBoxKitAllowance >
                                                        0
                                                          ? _c("div", [
                                                              _c("b", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.FormMSG(
                                                                      116,
                                                                      "Box kit: "
                                                                    )
                                                                  )
                                                                ),
                                                              ]),
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.displayCurrency(
                                                                      _vm.curTs
                                                                        .weeklyBoxKitAllowance
                                                                    )
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ])
                                                          : _vm._e(),
                                                        _vm.curTs
                                                          .dailyTotAllowances >
                                                        0
                                                          ? _c("div", [
                                                              _c("b", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.FormMSG(
                                                                      117,
                                                                      "Total Daily: "
                                                                    )
                                                                  )
                                                                ),
                                                              ]),
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.displayCurrency(
                                                                      _vm.curTs
                                                                        .dailyTotAllowances
                                                                    )
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ])
                                                          : _vm._e(),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                            _c("b-form-input", {
                                              attrs: {
                                                disabled: true,
                                                value: _vm.displayCurrency(
                                                  (isNaN(
                                                    _vm.curTs.dailyTotAllowances
                                                  )
                                                    ? 0
                                                    : _vm.curTs
                                                        .dailyTotAllowances) +
                                                    (isNaN(
                                                      _vm.curTs
                                                        .weeklyTotAllowances
                                                    )
                                                      ? 0
                                                      : _vm.curTs
                                                          .weeklyTotAllowances)
                                                ),
                                                type: "text",
                                                id: "allowances",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "12", sm: "12" } },
                                      [
                                        _vm.curTs.comment.length > 0
                                          ? _c(
                                              "b-form-group",
                                              {
                                                attrs: {
                                                  label: _vm.FormMSG(
                                                    19,
                                                    "Comment:"
                                                  ),
                                                  "label-for": "comment",
                                                  "label-cols": 2,
                                                },
                                              },
                                              [
                                                _c("b-form-textarea", {
                                                  attrs: {
                                                    disabled: true,
                                                    id: "comment",
                                                    rows: "2",
                                                  },
                                                  model: {
                                                    value: _vm.curTs.comment,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.curTs,
                                                        "comment",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "curTs.comment",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.TsDays
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "card card-border-blue-light mt-3",
                                    style: `${
                                      _vm.$screen.width <= 992
                                        ? "margin-bottom: 112px;"
                                        : ""
                                    }`,
                                  },
                                  [
                                    _vm.canSeeSalary
                                      ? _c(
                                          "b-form-checkbox",
                                          {
                                            staticClass: "pj-cb pb-1",
                                            attrs: {
                                              id: "showGridDetails",
                                              size: "lg",
                                            },
                                            model: {
                                              value: _vm.showGridDetails,
                                              callback: function ($$v) {
                                                _vm.showGridDetails = $$v
                                              },
                                              expression: "showGridDetails",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    1224,
                                                    "Show salary details"
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "b-row",
                                      [
                                        _c(
                                          "b-col",
                                          { attrs: { cols: "12", xl: "12" } },
                                          [
                                            _vm.$screen.width < 992
                                              ? _c("card-list-builder", {
                                                  attrs: {
                                                    "use-new-version": true,
                                                    items: _vm.TsDays,
                                                    fields: _vm.tsMobileFields,
                                                    "has-badge": true,
                                                    "is-badge-use-props": true,
                                                    "badge-class":
                                                      "statusClass",
                                                    "hide-status": false,
                                                    "badge-value":
                                                      "validatedStatus",
                                                    "toggle-mode": true,
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "lunch",
                                                        fn: function (data) {
                                                          return [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex justify-content-end align-items-center",
                                                              },
                                                              [
                                                                data.item
                                                                  .totPerDiem >
                                                                0
                                                                  ? _c(
                                                                      "button",
                                                                      {
                                                                        directives:
                                                                          [
                                                                            {
                                                                              name: "b-tooltip",
                                                                              rawName:
                                                                                "v-b-tooltip.hover.left",
                                                                              modifiers:
                                                                                {
                                                                                  hover: true,
                                                                                  left: true,
                                                                                },
                                                                            },
                                                                          ],
                                                                        staticClass:
                                                                          "btn-transparent text-color-rhapsody-in-blue",
                                                                        attrs: {
                                                                          title:
                                                                            _vm.tooltipContentPerIdem(
                                                                              data.item
                                                                            ),
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          _vm.getLucideIcon(
                                                                            "Info"
                                                                          ),
                                                                          {
                                                                            tag: "component",
                                                                            attrs:
                                                                              {
                                                                                color:
                                                                                  "#225CBD",
                                                                                size: 20,
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e(),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "adjust-icon-ts-tsd",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.displayCurrency(
                                                                          data
                                                                            .item
                                                                            .totPerDiem
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                      {
                                                        key: "hotel",
                                                        fn: function (data) {
                                                          return [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex justify-content-end align-items-center",
                                                              },
                                                              [
                                                                data.item
                                                                  .totAllowances >
                                                                0
                                                                  ? _c(
                                                                      "button",
                                                                      {
                                                                        directives:
                                                                          [
                                                                            {
                                                                              name: "b-tooltip",
                                                                              rawName:
                                                                                "v-b-tooltip.hover.left",
                                                                              modifiers:
                                                                                {
                                                                                  hover: true,
                                                                                  left: true,
                                                                                },
                                                                            },
                                                                          ],
                                                                        staticClass:
                                                                          "btn-transparent text-color-rhapsody-in-blue",
                                                                        attrs: {
                                                                          title:
                                                                            _vm.tooltipContentAllowance(
                                                                              data.item
                                                                            ),
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          _vm.getLucideIcon(
                                                                            "Info"
                                                                          ),
                                                                          {
                                                                            tag: "component",
                                                                            attrs:
                                                                              {
                                                                                color:
                                                                                  "#225CBD",
                                                                                size: 20,
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e(),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "adjust-icon-ts-tsd",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.displayCurrency(
                                                                          data
                                                                            .item
                                                                            .totAllowances
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                      {
                                                        key: "rej",
                                                        fn: function (data) {
                                                          return [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex justify-content-end align-items-center w-100",
                                                              },
                                                              [
                                                                data.item
                                                                  .validated ===
                                                                  4 ||
                                                                data.item
                                                                  .validated ===
                                                                  16
                                                                  ? _c(
                                                                      _vm.getLucideIcon(
                                                                        _vm
                                                                          .ICONS
                                                                          .UNDO_2
                                                                          .name
                                                                      ),
                                                                      {
                                                                        tag: "component",
                                                                        attrs: {
                                                                          color:
                                                                            _vm
                                                                              .ICONS
                                                                              .UNDO_2
                                                                              .color,
                                                                          size: 22,
                                                                          "stroke-width": 2.5,
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.undoRejectTsDay(
                                                                                data
                                                                                  .item
                                                                                  .id
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    )
                                                                  : _c(
                                                                      _vm.getLucideIcon(
                                                                        _vm
                                                                          .ICONS
                                                                          .X_CIRCLE
                                                                          .name
                                                                      ),
                                                                      {
                                                                        tag: "component",
                                                                        attrs: {
                                                                          color:
                                                                            _vm
                                                                              .ICONS
                                                                              .X_CIRCLE
                                                                              .color,
                                                                          size: 22,
                                                                          "stroke-width": 2.5,
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.rejectTsDay(
                                                                                data
                                                                                  .item
                                                                                  .id
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    1396581677
                                                  ),
                                                })
                                              : _vm._e(),
                                            _vm.$screen.width >= 992
                                              ? _c("b-table", {
                                                  attrs: {
                                                    hover: _vm.hover,
                                                    responsive: "sm",
                                                    items: _vm.TsDays,
                                                    fields: _vm.tsFields,
                                                    "current-page":
                                                      _vm.currentPage,
                                                    "sticky-header": "700px",
                                                    "per-page": _vm.perPage,
                                                    "head-variant": _vm.hv,
                                                    bordered: "",
                                                    small: "",
                                                  },
                                                  on: {
                                                    "row-dblclicked":
                                                      _vm.rowClicked,
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "cell(dayType)",
                                                        fn: function (data) {
                                                          return [
                                                            _c("span", {
                                                              domProps: {
                                                                innerHTML:
                                                                  _vm._s(
                                                                    data.value
                                                                  ),
                                                              },
                                                            }),
                                                          ]
                                                        },
                                                      },
                                                      {
                                                        key: "cell(hhmm)",
                                                        fn: function (data) {
                                                          return [
                                                            _c("span", {
                                                              domProps: {
                                                                innerHTML:
                                                                  _vm._s(
                                                                    data.value
                                                                  ),
                                                              },
                                                            }),
                                                          ]
                                                        },
                                                      },
                                                      {
                                                        key: "cell(hhmmOvt)",
                                                        fn: function (data) {
                                                          return [
                                                            _c("span", {
                                                              domProps: {
                                                                innerHTML:
                                                                  _vm._s(
                                                                    data.value
                                                                  ),
                                                              },
                                                            }),
                                                          ]
                                                        },
                                                      },
                                                      {
                                                        key: "cell(hhmmOvt1)",
                                                        fn: function (data) {
                                                          return [
                                                            _c("span", {
                                                              domProps: {
                                                                innerHTML:
                                                                  _vm._s(
                                                                    data.value
                                                                  ),
                                                              },
                                                            }),
                                                          ]
                                                        },
                                                      },
                                                      {
                                                        key: "cell(hhmmOvt2)",
                                                        fn: function (data) {
                                                          return [
                                                            _c("span", {
                                                              domProps: {
                                                                innerHTML:
                                                                  _vm._s(
                                                                    data.value
                                                                  ),
                                                              },
                                                            }),
                                                          ]
                                                        },
                                                      },
                                                      {
                                                        key: "cell(hhmmTransport)",
                                                        fn: function (data) {
                                                          return [
                                                            _c("span", {
                                                              domProps: {
                                                                innerHTML:
                                                                  _vm._s(
                                                                    data.value
                                                                  ),
                                                              },
                                                            }),
                                                          ]
                                                        },
                                                      },
                                                      {
                                                        key: "cell(hhmmNight)",
                                                        fn: function (data) {
                                                          return [
                                                            _c("span", {
                                                              domProps: {
                                                                innerHTML:
                                                                  _vm._s(
                                                                    data.value
                                                                  ),
                                                              },
                                                            }),
                                                          ]
                                                        },
                                                      },
                                                      {
                                                        key: "cell(hhmmTooEarly)",
                                                        fn: function (data) {
                                                          return [
                                                            _c("span", {
                                                              domProps: {
                                                                innerHTML:
                                                                  _vm._s(
                                                                    data.value
                                                                  ),
                                                              },
                                                            }),
                                                          ]
                                                        },
                                                      },
                                                      {
                                                        key: "cell(remark)",
                                                        fn: function (data) {
                                                          return [
                                                            data.item.remark
                                                              ? _c(
                                                                  "Button",
                                                                  {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "tooltip",
                                                                          rawName:
                                                                            "v-tooltip.left.focus",
                                                                          value:
                                                                            data
                                                                              .item
                                                                              .remark,
                                                                          expression:
                                                                            "data.item.remark",
                                                                          modifiers:
                                                                            {
                                                                              left: true,
                                                                              focus: true,
                                                                            },
                                                                        },
                                                                      ],
                                                                    staticClass:
                                                                      "p-0 p-button-text p-button-info",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "MessageCircle"
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        },
                                                      },
                                                      {
                                                        key: "cell(validatedStatus)",
                                                        fn: function (data) {
                                                          return [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "wrap-status d-flex",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    class: `status ${data.item.statusClass}`,
                                                                    staticStyle:
                                                                      {
                                                                        "font-size":
                                                                          "0.7rem",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                        _vm._s(
                                                                          data
                                                                            .item
                                                                            .validatedStatus
                                                                        ) +
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                      _vm.showLunchPerDiem() ||
                                                      _vm.showHotelPerDiem() ||
                                                      _vm.showDinnerPerDiem() ||
                                                      _vm.showAbroadPerDiem()
                                                        ? {
                                                            key: "cell(lunch)",
                                                            fn: function (
                                                              data
                                                            ) {
                                                              return [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "d-flex justify-content-end align-items-center",
                                                                  },
                                                                  [
                                                                    data.item
                                                                      .totPerDiem >
                                                                    0
                                                                      ? _c(
                                                                          "button",
                                                                          {
                                                                            directives:
                                                                              [
                                                                                {
                                                                                  name: "b-tooltip",
                                                                                  rawName:
                                                                                    "v-b-tooltip.hover.left",
                                                                                  modifiers:
                                                                                    {
                                                                                      hover: true,
                                                                                      left: true,
                                                                                    },
                                                                                },
                                                                              ],
                                                                            staticClass:
                                                                              "btn-transparent text-color-rhapsody-in-blue",
                                                                            attrs:
                                                                              {
                                                                                title:
                                                                                  _vm.tooltipContentPerIdem(
                                                                                    data.item
                                                                                  ),
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              _vm.getLucideIcon(
                                                                                "Info"
                                                                              ),
                                                                              {
                                                                                tag: "component",
                                                                                attrs:
                                                                                  {
                                                                                    color:
                                                                                      "#225CBD",
                                                                                    size: 20,
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "adjust-icon-ts-tsd",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.displayCurrency(
                                                                              data
                                                                                .item
                                                                                .totPerDiem
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            },
                                                          }
                                                        : null,
                                                      {
                                                        key: "cell(hotel)",
                                                        fn: function (data) {
                                                          return [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex justify-content-end align-items-center",
                                                              },
                                                              [
                                                                data.item
                                                                  .totAllowances >
                                                                0
                                                                  ? _c(
                                                                      "button",
                                                                      {
                                                                        directives:
                                                                          [
                                                                            {
                                                                              name: "b-tooltip",
                                                                              rawName:
                                                                                "v-b-tooltip.hover.left",
                                                                              modifiers:
                                                                                {
                                                                                  hover: true,
                                                                                  left: true,
                                                                                },
                                                                            },
                                                                          ],
                                                                        staticClass:
                                                                          "btn-transparent text-color-rhapsody-in-blue",
                                                                        attrs: {
                                                                          title:
                                                                            _vm.tooltipContentAllowance(
                                                                              data.item
                                                                            ),
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          _vm.getLucideIcon(
                                                                            "Info"
                                                                          ),
                                                                          {
                                                                            tag: "component",
                                                                            attrs:
                                                                              {
                                                                                color:
                                                                                  "#225CBD",
                                                                                size: 20,
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e(),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "adjust-icon-ts-tsd",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.displayCurrency(
                                                                          data
                                                                            .item
                                                                            .totAllowances
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                      {
                                                        key: "cell(rej)",
                                                        fn: function (data) {
                                                          return [
                                                            data.item
                                                              .validated == 4 ||
                                                            data.item
                                                              .validated == 16
                                                              ? _c(
                                                                  "b-button",
                                                                  {
                                                                    staticClass:
                                                                      "btn bg-transparent border-0",
                                                                    attrs: {
                                                                      size: "sm",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.undoRejectTsDay(
                                                                            data
                                                                              .item
                                                                              .id
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      _vm.getLucideIcon(
                                                                        _vm
                                                                          .ICONS
                                                                          .UNDO_2
                                                                          .name
                                                                      ),
                                                                      {
                                                                        tag: "component",
                                                                        attrs: {
                                                                          color:
                                                                            _vm
                                                                              .ICONS
                                                                              .UNDO_2
                                                                              .color,
                                                                          size: 20,
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _c(
                                                                  "b-button",
                                                                  {
                                                                    staticClass:
                                                                      "btn bg-transparent border-0",
                                                                    attrs: {
                                                                      size: "sm",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.rejectTsDay(
                                                                            data
                                                                              .item
                                                                              .id
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      _vm.getLucideIcon(
                                                                        _vm
                                                                          .ICONS
                                                                          .X_CIRCLE
                                                                          .name
                                                                      ),
                                                                      {
                                                                        tag: "component",
                                                                        attrs: {
                                                                          color:
                                                                            _vm
                                                                              .ICONS
                                                                              .X_CIRCLE
                                                                              .color,
                                                                          size: 20,
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-row",
                                      {
                                        class: `${
                                          _vm.$screen.width <= 576
                                            ? "footer-fixed"
                                            : ""
                                        }`,
                                      },
                                      [
                                        _c(
                                          "b-col",
                                          { attrs: { md: "3", sm: "12" } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "w-100 d-flex justify-content-center align-items-center",
                                              },
                                              [
                                                _c(
                                                  "b-button",
                                                  {
                                                    staticClass:
                                                      "d-flex justify-content-center align-items-center",
                                                    class: `${
                                                      _vm.$screen.width <= 576
                                                        ? "w-100 mb-2"
                                                        : "w-80"
                                                    }`,
                                                    attrs: {
                                                      disabled:
                                                        this.canNotValidateTs(),
                                                      size: "md",
                                                      variant:
                                                        "outline-success",
                                                    },
                                                    on: {
                                                      click:
                                                        _vm.validateTimesheet,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon(
                                                        _vm.ICONS.CHECK_SQUARE
                                                          .name
                                                      ),
                                                      {
                                                        tag: "component",
                                                        staticClass: "mr-2",
                                                        attrs: { size: 20 },
                                                      }
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          "margin-top": "1px",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            this.FormMSG(
                                                              30,
                                                              "Validate Timesheet"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "b-col",
                                          { attrs: { md: "3", sm: "12" } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "w-100 d-flex justify-content-center",
                                              },
                                              [
                                                _c(
                                                  "b-button",
                                                  {
                                                    staticClass:
                                                      "d-flex justify-content-center align-items-center",
                                                    class: `${
                                                      _vm.$screen.width <= 576
                                                        ? "w-100 mb-2"
                                                        : "w-80"
                                                    }`,
                                                    attrs: {
                                                      size: "md",
                                                      variant:
                                                        "outline-primary",
                                                      disabled:
                                                        _vm.isLoadingDownload,
                                                    },
                                                    on: {
                                                      click: _vm.downloadXls,
                                                    },
                                                  },
                                                  [
                                                    _c("b-spinner", {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            _vm.isLoadingDownload,
                                                          expression:
                                                            "isLoadingDownload",
                                                        },
                                                      ],
                                                      staticClass: "mr-2",
                                                      attrs: { small: "" },
                                                    }),
                                                    !_vm.isLoadingDownload
                                                      ? _c(
                                                          _vm.getLucideIcon(
                                                            _vm.ICONS.DOWNLOAD
                                                              .name
                                                          ),
                                                          {
                                                            tag: "component",
                                                            staticClass: "mr-2",
                                                            attrs: { size: 20 },
                                                          }
                                                        )
                                                      : _vm._e(),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          "margin-top": "1px",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            this.FormMSG(
                                                              221,
                                                              "Download this Timesheet"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "b-col",
                                          { attrs: { md: "3", sm: "12" } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "w-100 d-flex justify-content-center",
                                              },
                                              [
                                                _c(
                                                  "b-button",
                                                  {
                                                    staticClass:
                                                      "d-flex justify-content-center align-items-center",
                                                    class: `${
                                                      _vm.$screen.width <= 576
                                                        ? "w-100 mb-2"
                                                        : "w-80"
                                                    }`,
                                                    attrs: {
                                                      size: "md",
                                                      variant:
                                                        "outline-primary",
                                                      disabled:
                                                        _vm.isLoadingSendMail,
                                                    },
                                                    on: {
                                                      click: _vm.sendXlsByEmail,
                                                    },
                                                  },
                                                  [
                                                    _c("b-spinner", {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            _vm.isLoadingSendMail,
                                                          expression:
                                                            "isLoadingSendMail",
                                                        },
                                                      ],
                                                      staticClass: "mr-2",
                                                      attrs: { small: "" },
                                                    }),
                                                    !_vm.isLoadingSendMail
                                                      ? _c(
                                                          _vm.getLucideIcon(
                                                            _vm.ICONS.SEND.name
                                                          ),
                                                          {
                                                            tag: "component",
                                                            staticClass: "mr-2",
                                                            attrs: { size: 20 },
                                                          }
                                                        )
                                                      : _vm._e(),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          "margin-top": "1px",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            this.FormMSG(
                                                              31,
                                                              "Send me this Timesheet by Email"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "b-col",
                                          { attrs: { md: "3", sm: "12" } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "w-100 d-flex justify-content-center",
                                              },
                                              [
                                                _c(
                                                  "b-button",
                                                  {
                                                    staticClass:
                                                      "d-flex justify-content-center align-items-center",
                                                    class: `${
                                                      _vm.$screen.width <= 576
                                                        ? "w-100 mb-2"
                                                        : "w-80"
                                                    }`,
                                                    attrs: {
                                                      size: "md",
                                                      variant: "outline-danger",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.successModalRejectTimesheet = true
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon(
                                                        _vm.ICONS.X_CIRCLE.name
                                                      ),
                                                      {
                                                        tag: "component",
                                                        staticClass: "mr-2",
                                                        attrs: { size: 20 },
                                                      }
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          "margin-top": "1px",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            this.FormMSG(
                                                              32,
                                                              "Reject Timesheet"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-modal",
                                      {
                                        staticClass: "modal-success",
                                        attrs: {
                                          "header-class":
                                            "header-class-modal-doc-package",
                                          title: _vm.FormMSG(33, "Success!"),
                                          "ok-variant": "success",
                                          "ok-only": "",
                                        },
                                        on: {
                                          ok: function ($event) {
                                            _vm.successModal = false
                                          },
                                        },
                                        model: {
                                          value: _vm.successModal,
                                          callback: function ($$v) {
                                            _vm.successModal = $$v
                                          },
                                          expression: "successModal",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(
                                              this.FormMSG(
                                                34,
                                                "The timesheet has been sent to your Email address"
                                              )
                                            ) +
                                            "\n\t\t\t\t\t\t\t\t"
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "b-modal",
                                      {
                                        staticClass: "modal-success",
                                        attrs: {
                                          "header-class":
                                            "header-class-modal-doc-package",
                                          title: this.FormMSG(33, "Success!"),
                                          "ok-variant": "success",
                                          "ok-only": "",
                                        },
                                        on: {
                                          ok: function ($event) {
                                            _vm.successModalValidated = false
                                          },
                                        },
                                        model: {
                                          value: _vm.successModalValidated,
                                          callback: function ($$v) {
                                            _vm.successModalValidated = $$v
                                          },
                                          expression: "successModalValidated",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(
                                              this.FormMSG(
                                                35,
                                                "The timesheet has been validated"
                                              )
                                            ) +
                                            "\n\t\t\t\t\t\t\t\t"
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "b-modal",
                                      {
                                        staticClass: "modal-danger",
                                        attrs: {
                                          "header-class":
                                            "header-class-modal-doc-package",
                                          title: _vm.FormMSG(
                                            36,
                                            "Refuse this timesheet"
                                          ),
                                          "ok-variant": "danger",
                                          "ok-only": "",
                                        },
                                        on: {
                                          ok: this.finaliseTimesheetReject,
                                        },
                                        model: {
                                          value:
                                            _vm.successModalRejectTimesheet,
                                          callback: function ($$v) {
                                            _vm.successModalRejectTimesheet =
                                              $$v
                                          },
                                          expression:
                                            "successModalRejectTimesheet",
                                        },
                                      },
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label: _vm.FormMSG(
                                                37,
                                                "Enter your comment:"
                                              ),
                                              "label-for": "comment",
                                              "label-cols": 4,
                                            },
                                          },
                                          [
                                            _c("b-form-textarea", {
                                              attrs: {
                                                id: "comment",
                                                placeholder: this.FormMSG(
                                                  38,
                                                  "Enter your remarks..."
                                                ),
                                                rows: "3",
                                              },
                                              model: {
                                                value: _vm.rejectComment,
                                                callback: function ($$v) {
                                                  _vm.rejectComment = $$v
                                                },
                                                expression: "rejectComment",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-modal",
                                      {
                                        staticClass: "modal-danger",
                                        attrs: {
                                          "header-class":
                                            "header-class-modal-doc-package",
                                          title: _vm.FormMSG(
                                            39,
                                            "Refuse this day"
                                          ),
                                          "ok-variant": "danger",
                                          "ok-only": "",
                                        },
                                        on: { ok: this.finaliseTsDayReject },
                                        model: {
                                          value: _vm.successModalRejectedTsDay,
                                          callback: function ($$v) {
                                            _vm.successModalRejectedTsDay = $$v
                                          },
                                          expression:
                                            "successModalRejectedTsDay",
                                        },
                                      },
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label: _vm.FormMSG(
                                                37,
                                                "Enter your comment:"
                                              ),
                                              "label-for": "comment",
                                              "label-cols": 4,
                                            },
                                          },
                                          [
                                            _c("b-form-textarea", {
                                              attrs: {
                                                id: "comment",
                                                placeholder: this.FormMSG(
                                                  38,
                                                  "Enter your remarks..."
                                                ),
                                                rows: "3",
                                              },
                                              model: {
                                                value: _vm.rejectComment,
                                                callback: function ($$v) {
                                                  _vm.rejectComment = $$v
                                                },
                                                expression: "rejectComment",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("script-export-modal", {
              attrs: {
                items: _vm.scripts,
                custom: _vm.customReport,
                "export-type": "ts",
              },
              on: {
                "script-export-modal:loading": _vm.handleLoading,
                "script-export-modal:closed": _vm.timeSheetRerpotOnClosed,
              },
              model: {
                value: _vm.isScriptTimeSheetReportOpen,
                callback: function ($$v) {
                  _vm.isScriptTimeSheetReportOpen = $$v
                },
                expression: "isScriptTimeSheetReportOpen",
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }